import React, { useEffect, useState } from "react";
import { Modal, Button, Badge, Dropdown, Form } from "react-bootstrap";
import { BiShow, BiDownload } from "react-icons/bi";
import "./FeedbackDetails.css"; // Import the CSS file
import { updateFeedbackStatus } from "../../Services/apiServices";
import { getUserDetails } from "../../localStorage.js";
import { SendFeedbackOutcomeMail } from "../../Services/apiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FeedbackDetails = ({
  feedback,
  isOpen,
  onClose,
  refreshFeedbackList,
  employeeDetails,
}) => {
  console.log(employeeDetails);
  const [status, setStatus] = useState(feedback.status);
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [inProgressComment, setInProgressComment] = useState();
  const [closingComment, setClosingComment] = useState();
  const [showCommentBox, setShowCommentBox] = useState(false);
  const userDetails = getUserDetails();

  useEffect(() => {
    setInProgressComment(feedback.in_progress_comment);
    setClosingComment(feedback.closing_comment);
  }, []);

  if (!isOpen || !feedback) return null; // Ensure feedback is not null or undefined

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setShowStatusOptions(false);
    setShowCommentBox(true);
    // Reset comments fields when status changes
    setInProgressComment("");
    setClosingComment("");
  };

  const toggleStatusOptions = () => {
    setShowStatusOptions(!showStatusOptions);
  };

  const handleReviewerCommentChange = (e) => {
    const { name, value } = e.target;
    if (name === "reviewerCommentOpen") {
      setInProgressComment(value);
    } else if (name === "reviewerCommentClose") {
      setClosingComment(value);
    }
  };

  const handleSave = () => {
    if (!(inProgressComment.trim() || closingComment.trim())) {
      alert("Comment is required");
      return;
    }
    updateFeedbackStatus(
      feedback.id,
      status,
      inProgressComment,
      closingComment,
      userDetails.token,
      userDetails.type
    )
      .then((res) => {
        console.log("Updated Successfully");
        refreshFeedbackList();
        onClose();
        // if (closingComment && feedback.email) {
        //   SendFeedbackOutcomeMail(
        //     feedback.name,
        //     feedback.email,
        //     closingComment,
        //     userDetails.token,
        //     userDetails.type
        //   );
        // }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const getStatusBadgeVariant = (status) => {
    switch (status.toLowerCase()) {
      case "open":
        return "red";
      case "in progress":
        return "orange";
      case "closed":
        return "green";
      default:
        return "secondary";
    }
  };

  return (
    <div>
      <Modal show={isOpen} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Suggestion Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent:"space-between"
            }}
            className="details-group"
          >
            <p style={{ marginRight: "10px", display:"flex" }}>
              <strong>Status:</strong>
              &nbsp;
              <div
                // bg={getStatusBadgeVariant(status)}
                onClick={toggleStatusOptions}
                style={{ width: "100px", fontWeight:"bold", color:getStatusBadgeVariant(status) }}
              >
                {status}
              </div>
            </p>
            {feedback.status !== "Closed" &&
            employeeDetails[0].suggestion_department_head === 1 ? (
              <Dropdown className="status-dropdown">
                <Dropdown.Toggle disabled={showCommentBox} variant="outline-secondary" split>
                  Change Status
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ minWidth: "100%" }}>
                  {feedback.status === "Open" && (
                    <>
                      {/* <Dropdown.Item onClick={() => handleStatusChange("Open")}>
                        Open
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleStatusChange("In Progress")}
                      >
                        In Progress
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleStatusChange("Closed")}
                      >
                        Close
                      </Dropdown.Item>
                    </>
                  )}
                  
                  {feedback.status === "In Progress" && (
                    <Dropdown.Item onClick={() => handleStatusChange("Closed")}>
                      Close
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </div>

          {status === "In Progress" && showCommentBox && (
            <Form.Group className="details-group">
              <Form.Label>
                Reviewer Comment (When moving to In Progress)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="reviewerCommentOpen"
                value={inProgressComment}
                onChange={handleReviewerCommentChange}
              />
            </Form.Group>
          )}

          {status === "Closed" && showCommentBox && (
            <Form.Group className="details-group">
              <Form.Label>Reviewer Comment (When closing)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="reviewerCommentClose"
                value={closingComment}
                onChange={handleReviewerCommentChange}
              />
            </Form.Group>
          )}

          {inProgressComment || closingComment ? (
            <div className="details-group">
              <h5>
                <strong>Comments</strong>
              </h5>
              {inProgressComment && (
                <p>
                  <strong>
                    <em>Reviewer Comment (In Progress):</em>
                  </strong>{" "}
                  {inProgressComment}
                </p>
              )}

              {closingComment && (
                <p>
                  <strong>
                    <em>Reviewer Comment (Close):</em>
                  </strong>
                  {closingComment}
                </p>
              )}
            </div>
          ) : null}

          <div className="details-group">
            <h5>
              <strong>User Details</strong>
            </h5>
            <p>
              <strong>
                <em>Name:</em>
              </strong>{" "}
              {feedback.name ? feedback.name : "N/A"}
            </p>
            <p>
              <strong>
                <em>Email:</em>
              </strong>{" "}
              {feedback.email ? feedback.email : "N/A"}
            </p>
            <p>
              <strong>
                <em>Contact:</em>
              </strong>{" "}
              {feedback.contact ? feedback.contact : "N/A"}
            </p>
          </div>

          <div className="details-group">
            <h5>
              <strong>Department and Category</strong>
            </h5>
            <p>
              <strong>
                <em>Department:</em>
              </strong>{" "}
              {feedback.department}
            </p>
            <p>
              <strong>
                <em>Category:</em>
              </strong>{" "}
              {feedback.category}
            </p>
          </div>

          <div className="details-group">
            <h5>
              <strong>Message Details</strong>
            </h5>
            <p>
              <strong>
                <em>Brief description of current observation:</em>
              </strong>{" "}
              {feedback.message}
            </p>
            <p>
              <strong>
                <em>Brief Idea/Suggestion:</em>
              </strong>{" "}
              {feedback.brief_idea}
            </p>
            <p>
              <strong>
                <em>Impact On Business:</em>
              </strong>{" "}
              {feedback.impact_on_business}
            </p>
            {feedback.audio && (
              <p style={{display:"flex", alignItems:"center"}}>
                <strong>Audio:</strong>{" "}
                <audio controls>
                  <source src={feedback.audio} type="audio/mpeg" />
                  Your browser does not support the audio player.
                </audio>
                <a
                  href={feedback.audio}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BiDownload color="black" size="30px"/>
                </a>
              </p>
            )}
            <p>
              <strong>
                <em>Date:</em>
              </strong>{" "}
              {feedback.CreatedAt.split("T")[0].split("-").reverse().join("-")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {employeeDetails[0].suggestion_department_head === 1 &&
          !feedback.closing_comment ? (
            <Button
              style={{ background: "#004713", width:"200px" }}
              variant="primary"
              onClick={handleSave}
              
            >
              Save
            </Button>
          ) : (
            <Button
              style={{ background: "#004713", width:"200px"  }}
              variant="primary"
              onClick={onClose}
            >
              Done
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer autoClose="1500" />
    </div>
  );
};

export default FeedbackDetails;
