import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FeedbackForm.css";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import {
  saveFeedback,
  saveUserDetails,
  uploadAudioBlob,
  getDepartmentsByPlantId,
  SendFeedbackSubmissionMail,
  SendFeedbackSubmissionMailToUser,
} from "../../Services/apiServices.js";
import think_aloud_logo from "../../Assets/Think_Aloud.png";
import dabur_logo from "../../Assets/Dabur_Logo.svg.png";
import think_aloud_new from "../../Assets/Think_Aloud_new.png";
import MI_logo_green from "../../Assets/MI_logo_green.png"

const ThankYouScreen = ({ feedbackId, orgIdAndPlantId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleOkClick = (event) => {
    event.preventDefault();
    if (contact.length > 0 && contact.length < 10) {
      setError("Phone number is invalid.");
      return;
    }
    window.location.reload();
  };

  return (
    <div className="form-container">
      {/* <div className="dabur-logo" style={{ textAlign: "center" }}>
        <img src={dabur_logo} alt="dabur logo" style={{ height: "50px" }} />
      </div> */}
      <br />
      <form className="feedback-form" onSubmit={handleOkClick}>
        <div style={{ textAlign: "center" }}>
          <img
            src={MI_logo_green}
            alt="think logo"
            style={{ height: "12vh" }}
          />
        </div>
        <br />
        <h3 style={{ color: "white" }} className="form-title">
          Suggestion Submitted!
        </h3>
        <div className="tick-icon-container">
          <svg
            className="tick-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#28a745"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
        <p className="thankyou-message">
          Appreciate your suggestion, we will assess for rolling it out. Please
          help us in improvements going forward.
        </p>
        <button className="btn submit-btn" type="submit">
          OK
        </button>
      </form>
      <br />
      <div style={{ textAlign: "center", color: "#ffffff" }}>
        Powered by{" "}
        <a
          href="https://www.momentumindia.in"
          target="_blank"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          Momentum
        </a>{" "}
        ©
      </div>
    </div>
  );
};

const UserDetailScreen = ({
  orgIdAndPlantId,
  formData,
  departmentList,
  empList,
  setScreenNumber,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const [feedbackId, setFeedbackId] = useState(null);

  const emailPattern =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|in|tech|info)$/;

  const handleOkClick = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      setError("Name is required.");
      return;
    }
    if (contact.length < 10) {
      setError("Phone number is invalid.");
      return;
    }
    if (!emailPattern.test(email)) {
      setEmailError("Email is invalid.");
      return;
    }

    const categoryToSave =
      formData.category === "Others"
        ? formData.customCategory
        : formData.category;

    const isOthers = formData.category === "Others" ? 1 : 0;

    const selectedDepartment = departmentList.find(
      (dept) => dept.name === formData.department
    );

    if (!selectedDepartment) {
      console.error("Selected department not found in departmentList.");
      return;
    }

    const formDataToSend = {
      ...formData,
      category: categoryToSave,
      department_ref_id: selectedDepartment.ref_id,
      is_others: isOthers,
      name,
      email,
      contact,
    };

    const deptHeadDetials = empList.filter(
      (e) =>
        e.department_ref_id === formDataToSend.department_ref_id &&
        e.suggestion_department_head === 1
    );

    saveFeedback(formDataToSend)
      .then((res) => {
        setFeedbackId(res.id);
        SendFeedbackSubmissionMail(
          deptHeadDetials,
          formDataToSend.message,
          formDataToSend.category,
          formDataToSend.department,
          formDataToSend.brief_idea,
          formDataToSend.impact_on_business,
          name,
          email,
          contact
        );
        SendFeedbackSubmissionMailToUser(name, email);
        setScreenNumber(3);
      })
      .catch((err) => {
        console.error("Error saving feedback:", err);
      });
  };

  const handleNameChange = (e) => {
    const value = e.target.value.slice(0, 100);
    setName(value);
    setNameError(value.length >= 100 ? "Name cannot exceed 100 characters." : "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").slice(0, 100);
    setEmail(value);
    setEmailError(value.length >= 100 ? "Email cannot exceed 100 characters." : "");
  };

  return (
    <div className="form-container">
      {/* <div className="dabur-logo" style={{ textAlign: "center" }}>
        <img src={dabur_logo} alt="dabur logo" style={{ height: "50px" }} />
      </div> */}
      <br />
      <form className="feedback-form" onSubmit={handleOkClick}>
        <div style={{ textAlign: "center" }}>
          <img
            src={MI_logo_green}
            alt="think logo"
            style={{ height: "12vh" }}
          />
        </div>
        <br />
        <p className="next-steps">Please tell us about yourself.</p>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            required
          />
          {nameError && (
            <p style={{ color: "maroon" }} className="error-message">
              {nameError}
            </p>
          )}
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          {emailError && (
            <p style={{ color: "maroon" }} className="error-message">
              {emailError}
            </p>
          )}
        </div>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                +91
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              placeholder="Contact"
              value={contact}
              onChange={(e) => setContact(e.target.value.slice(0, 10))}
              maxLength={10}
              required
            />
          </div>
          {error && (
            <p style={{ color: "maroon" }} className="error-message">
              {error}
            </p>
          )}
        </div>
        <button className="btn submit-btn" type="submit">
          Submit
        </button>
      </form>
      <br />
      <div style={{ textAlign: "center", color: "#ffffff" }}>
        Powered by{" "}
        <a
          href="https://www.momentumindia.in"
          target="_blank"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          Momentum
        </a>{" "}
        ©
      </div>
    </div>
  );
};

const FeedbackForm = ({
  departmentList,
  orgId,
  plantId,
  orgIdAndPlantId,
  empList,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    department: "",
    department_ref_id: "", // Add department_ref_id to formData
    category: "",
    message: "",
    brief_idea: "",
    impact_on_business: "",
    org_id: "",
    plant_id: "",
  });
  const [listening, setListening] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  // const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [screenNumber, setScreenNumber] = useState(1);
  const [speechRecognition, setSpeechRecognition] = useState(null);
  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [feedbackId, setFeedbackId] = useState(null);
  const predefinedCategories = [
    "Cost Optimization",
    "Sales & Marketing Promotion Campaign",
    "Innovation",
    "Digitization & Technology",
    "New Product",
    "Process Efficiencies/Sustainability",
    "Others",
  ];

  useEffect(() => {
    if (orgId && plantId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        org_id: orgId,
        plant_id: plantId,
      }));
    }
  }, [orgId, plantId]);

  const startSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition is not supported in this browser.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      setListening(true);
      startRecording();
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setFormData((prevData) => ({
        ...prevData,
        message: prevData.message + transcript + ". ",
      }));
    };

    recognition.onend = () => {
      setListening(false);
      stopRecording();
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setListening(false);
      stopRecording();
    };

    setSpeechRecognition(recognition);
    recognitionRef.current = recognition;
    recognition.start();
  };

  const startRecording = () => {
    if (!navigator.mediaDevices) {
      console.error("Media devices are not supported in this browser.");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        const chunks = [];

        mediaRecorder.ondataavailable = (event) => {
          chunks.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(chunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          uploadAudioBlob(audioBlob)
            .then((res) => {
              const audio = res.fileUrl;
              setFormData((prevData) => ({
                ...prevData,
                audio: audio ? audio : null,
              }));
            })
            .catch((error) => {
              console.error("Error uploading audio blob:", error);
            });
        };

        mediaRecorder.start();
      })
      .catch((err) => {
        console.error("Error accessing media devices:", err);
      });
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
    }
  };

  const stopSpeechRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setListening(false);
    }
    stopRecording();
  };

  const toggleSpeechRecognition = () => {
    if (listening) {
      stopSpeechRecognition();
    } else {
      startSpeechRecognition();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "customCategory") {
      const isValid = /^[a-zA-Z0-9 _-]{0,40}$/.test(value);
      if (!isValid) {
        alert(
          "Custom category can only contain letters, numbers, spaces, underscores, and hyphens, and must be 40 characters or less."
        );
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.message.trim() || !formData.brief_idea.trim() || !formData.impact_on_business.trim()) {
      alert("Please fill all the fields")
      return
    }
    setScreenNumber(2);
  };

  if (screenNumber == 1) {
    return (
      <div className="form-container">
        {/* <div className="dabur-logo" style={{ textAlign: "center" }}>
          <img src={dabur_logo} alt="dabur logo" style={{ height: "100%" }} />
        </div> */}
        <br />
        <form onSubmit={handleSubmit} className="feedback-form">
          <div style={{ textAlign: "center" }}>
            <img
              src={MI_logo_green}
              alt="think logo"
              style={{ height: "12vh" }}
            />
          </div>
          <hr />
          <div className="form-group-container">
            <div className="form-group">
              <select
                className="form-control"
                id="department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                required
              >
                <option style={{ color: "#808080", fontSize: "11px" }} value="">
                  Select Dept. for Suggestion
                </option>
                {departmentList.map((dept) => (
                  <option key={dept.name} value={dept.name}>
                    {dept.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <select
                className="form-control"
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option style={{ color: "#808080", fontSize: "11px" }} value="">
                  Select Category
                </option>
                {predefinedCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
              {formData.category === "Others" && (
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter custom category"
                    name="customCategory"
                    value={formData.customCategory || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              )}
            </div>
            <div className="form-group position-relative">
              <textarea
                className="form-control"
                placeholder="Brief description of current observation"
                id="message"
                name="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {/* <button
              type="button"
              className="btn voice-input-btn"
              onClick={toggleSpeechRecognition}
            >
              {listening ? <FaMicrophoneSlash /> : <FaMicrophone />}
            </button> */}
            </div>
            <div className="form-group position-relative">
              <textarea
                className="form-control"
                placeholder="Brief Idea/Suggestion"
                id="brief_idea"
                name="brief_idea"
                rows="3"
                value={formData.brief_idea}
                onChange={handleChange}
                required
              ></textarea>
              {/* <button
              type="button"
              className="btn voice-input-btn"
              onClick={toggleSpeechRecognition}
            >
              {listening ? <FaMicrophoneSlash /> : <FaMicrophone />}
            </button> */}
            </div>
            <div className="form-group position-relative">
              <textarea
                className="form-control"
                placeholder="Impact on business"
                id="impact_on_business"
                name="impact_on_business"
                rows="3"
                value={formData.impact_on_business}
                onChange={handleChange}
                required
              ></textarea>
              {/* <button
              type="button"
              className="btn voice-input-btn"
              onClick={toggleSpeechRecognition}
            >
              {listening ? <FaMicrophoneSlash /> : <FaMicrophone />}
            </button> */}
            </div>
          </div>

          <button type="submit" className="btn submit-btn">
            Next
          </button>
        </form>
        <br />
        <div style={{ textAlign: "center", color: "#ffffff" }}>
          Powered by{" "}
          <a
            href="https://www.momentumindia.in"
            target="_blank"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Momentum
          </a>{" "}
          ©
        </div>
      </div>
    );
  } else if (screenNumber == 2) {
    return (
      <UserDetailScreen
        // feedbackId={feedbackId}
        orgIdAndPlantId={orgIdAndPlantId}
        formData={formData}
        departmentList={departmentList}
        empList={empList}
        setScreenNumber={setScreenNumber}
      />
    );
  } else if (screenNumber == 3) {
    return <ThankYouScreen />;
  }
};

export default FeedbackForm;
