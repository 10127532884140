import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LoginForm.css";
import { login } from "../../Services/apiServices.js";
import { setUserDetails } from "../../localStorage.js";
import { BiEnvelope } from "react-icons/bi"; // Importing BiEnvelope icon from react-icons/bi
import { BsEye, BsEyeSlash } from "react-icons/bs"; // Importing BsEye and BsEyeSlash icons from react-icons/bs
import { useNavigate } from "react-router-dom";
// import think_aloud_logo from "../../Assets/Think_Aloud.png";
import dabur_logo from "../../Assets/Dabur_Logo.svg.png";
import think_aloud_new from "../../Assets/Think_Aloud_new.png";
import ReCAPTCHA from "react-google-recaptcha";
import MI_logo_green from "../../Assets/MI_logo_green.png"

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    // if (!captchaValue) {
    //   setError("Please complete the CAPTCHA.");
    //   recaptchaRef.current.reset();
    //   return;
    // }
    setLoading(true);
    await login(email, password, captchaValue)
      .then((res) => {
        if (res.success === true) {
          setUserDetails(res.data);
          // recaptchaRef.current.reset();
          navigate("/home");
          setLoading(false);
        } else {
          setError(res.message);
          // recaptchaRef.current.reset();
          setLoading(false)
        }
      })
      .catch((err) => {
        console.error(err);
        // recaptchaRef.current.reset();
        setLoading(false)
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div style={{zIndex:"1000"}} className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="form-container">
        {/* <div className="dabur-logo" style={{ textAlign: "center" }}>
          <img src={dabur_logo} alt="dabur logo" style={{ height: "100%" }} />
        </div> */}
        <br />
        <form onSubmit={handleLogin} className="feedback-form">
          <div style={{ textAlign: "center" }}>
            <img
              src={MI_logo_green}
              alt="think logo"
              style={{ height: "12vh" }}
            />
          </div>
          <hr />
          <h3 className="form-title">Login</h3>
          <div className="form-group">
            <label htmlFor="email" className="input-label">
              Email address
            </label>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{ borderRadius: "5px" }}
              />
              <BiEnvelope className="login-form-icon" />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
                style={{ borderRadius: "5px" }}
              />
              <span
                className="login-form-icon"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </span>
            </div>
          </div>
          {/* <div className="form-group">
          <ReCAPTCHA
            sitekey="6LectRUqAAAAAIFmOdboJgsVdIeaqptOoJeMF8mV"
            onChange={handleCaptchaChange}
            ref={recaptchaRef}
          />
        </div> */}
          {/* <div className="form-group recaptcha-container">
            <ReCAPTCHA
              sitekey="6LdbpBYqAAAAAEv_xIVEjWsAjtf_MMy4KwzllYS6"
              onChange={handleCaptchaChange}
              ref={recaptchaRef}
            />
          </div> */}

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{ width: "50%" }}
              type="submit"
              className="btn submit-btn"
            >
              Login
            </button>
            <div style={{ textAlign: "center" }}>
              <p
                onClick={handleForgotPassword}
                style={{ marginTop: "10px", color: "white", cursor: "pointer" }}
              >
                Reset Password
              </p>
            </div>
          </div>
          {error && <p className="text-danger mt-2">{error}</p>}
        </form>
        <br />
        <div style={{ textAlign: "center", color: "#ffffff" }}>
          Powered by{" "}
          <a
            href="https://www.momentumindia.in"
            target="_blank"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Momentum
          </a>{" "}
          ©
        </div>
      </div>
    </>
  );
};

export default LoginForm;
