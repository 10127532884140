import React, { useState } from 'react';
import './Sidebar.css'; // Import CSS file for Sidebar styling
import { Nav } from 'react-bootstrap';
import { BsGrid, BsChat } from 'react-icons/bs'; // React Bootstrap icons
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; // Example expand/collapse icons
import dabur_logo from "../../Assets/Dabur_Logo.svg.png";
import green_logo_only from "../../Assets/green_logo_only.png"
import mi_logo_green from "../../Assets/MI_logo_green.png"


const Sidebar = ({ onSidebarItemClick, currentView, sidebarOpen }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''} ${sidebarOpen ? 'open' : ''}`}>
      <Nav className="flex-column">
        <div style={{alignSelf:"center"}} className='dabur-logo-sidebar'>
          <a href='https://www.momentumindia.in/' target="_blank">
            <img src={mi_logo_green} alt="logo" style={{ height: "5vh", width: "7vw" }} />
          </a>
        </div>
        <Nav.Link style={{marginTop:"30px"}} className={`sidebar-options ${currentView === 'dashboard' ? 'active' : ''}`} onClick={() => onSidebarItemClick('dashboard')}>
          <div className="icon-container">
            {collapsed ? (
              <BsGrid className="icon" />
            ) : (
              <>
                <BsGrid className="icon" />
                <span className="sidebar-text">Dashboard</span>
              </>
            )}
          </div>
        </Nav.Link>
        <Nav.Link className={`sidebar-options ${currentView === 'feedback' ? 'active' : ''}`} onClick={() => onSidebarItemClick('feedback')}>
          <div className="icon-container">
            {collapsed ? (
              <BsChat className="icon" />
            ) : (
              <>
                <BsChat className="icon" />
                <span className="sidebar-text">Suggestions</span>
              </>
            )}
          </div>
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
