import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BiEnvelope } from "react-icons/bi"; // Importing BiEnvelope icon from react-icons/bi
import { useNavigate } from "react-router-dom";
import dabur_logo from "../../Assets/Dabur_Logo.svg.png";
import think_aloud_new from "../../Assets/Think_Aloud_new.png";
import { resetPassword } from "../../Services/apiServices";
import MI_logo_green from "../../Assets/MI_logo_green.png"

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;
    resetPassword(email).then(res => {
        navigate("/login")
    })
  };

  return (
    <div className="form-container">
      {/* <div className="dabur-logo" style={{ textAlign: "center" }}>
        <img src={dabur_logo} alt="dabur logo" style={{ height: "100%" }} />
      </div> */}
      <br />
      <form onSubmit={handleSubmit} className="feedback-form">
        <div style={{ textAlign: "center", cursor:"pointer" }} onClick={() => navigate("/login")}>
          <img src={MI_logo_green} alt="think logo" style={{height:"12vh"}} />
        </div>
        <hr />
        <h3 className="form-title">Get Password Change Link</h3>
        <div className="form-group">
          <label htmlFor="email" className="input-label">
            Email address
          </label>
          <div className="input-group">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ borderRadius: "5px" }}
            />
            <BiEnvelope className="login-form-icon" />
          </div>
        </div>
        <button type="submit" className="btn submit-btn">
          Get Link
        </button>
        <p style={{textAlign:"right"}}>Please check your email</p>
        {error && <p className="text-danger mt-2">{error}</p>}
      </form>
      <br />
      <div style={{ textAlign: "center", color:"#ffffff" }}>
        Powered by{" "}
        <a
          href="https://www.momentumindia.in"
          target="_blank"
          style={{color:"inherit", textDecoration:"none"}}
        >
          Momentum
        </a>{" "}
        ©
      </div>
    </div>
  );
};

export default ForgotPassword;
