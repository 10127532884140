import React, { useState } from "react";
import "./Navbar.css"; // Import CSS file for Navbar styling
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from "react-bootstrap";
import { PersonCircle, Gear, BoxArrowLeft } from "react-bootstrap-icons";
import { clearUserDetails } from "../../localStorage";
import { useNavigate } from "react-router-dom";
import UserProfileModal from "../UserProfileModal/UserProfileModal";
// import logo from "../../Assets/Think_Aloud.png";
import think_aloud_new from '../../Assets/Think_Aloud_new.png'
import mi_text from "../../Assets/mi_text.png"

const Navbar = ({ employeeDetails,fetchEmpDetials }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); // State to control the modal visibility

  const handleProfileClick = () => {
    setShowProfileModal(true); // Show the profile modal when profile is clicked
  };

  const handleLogoutClick = () => {
    clearUserDetails();
    navigate("/login");
  };

  return (
    <>
      <BootstrapNavbar bg="#FFFFFF" variant="light" style={{background:"#FFFFFF"}}>
        <BootstrapNavbar.Brand
          style={{ cursor: "pointer" }}
          onClick={() => window.location.reload()}
        >
          <img src={mi_text} alt="logo" style={{ height: "6vh", left:"" }} />
        </BootstrapNavbar.Brand>
        <Nav className="ml-auto align-items-center">
          <NavDropdown
            title={<PersonCircle size={25} color="black" />}
            id="basic-nav-dropdown"
            show={showDropdown}
            onToggle={() => setShowDropdown(!showDropdown)}
            className="custom-dropdown"
            alignRight={false}
          >
            <NavDropdown.Item style={{background:"#50b848", color:"#ffffff"}}>
              Hello {employeeDetails[0] ? employeeDetails[0].emp_name : "Employee Name"}

            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleProfileClick}>
              <Gear size={18} /> Profile
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogoutClick}>
              <BoxArrowLeft size={18} /> Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </BootstrapNavbar>

      {/* Profile Modal */}
      {employeeDetails[0] && (
        <UserProfileModal
          show={showProfileModal}
          onHide={() => setShowProfileModal(false)} // Hide the modal when requested
          employeeDetails={employeeDetails}
          fetchEmpDetials={fetchEmpDetials}
        />
      )}
    </>
  );
};

export default Navbar;
