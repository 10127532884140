import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import './QRCodeGenerator.css'; // Make sure this file is importing your custom styles
import { getOrgList, getPlantList } from '../../Services/apiServices';
import { getUserDetails, clearUserDetails } from '../../localStorage';
import { useNavigate } from 'react-router-dom';
import dabur_logo from "../../Assets/Dabur_Logo.svg.png";

const QRCodeGenerator = () => {
  const [organization, setOrganization] = useState('');
  const [plant, setPlant] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [orgList, setOrgList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null); // Track selected organization
  const [selectedPlant, setSelectedPlant] = useState(null); // Track selected plant
  const userDetails = getUserDetails();
  const navigate = useNavigate();

  useEffect(() => {
    getOrgList(userDetails.token, userDetails.type)
      .then((res) => {
        const orgs = res.data.map((org) => ({
          value: org.id,
          label: org.org_name,
        }));
        setOrgList(orgs);
      })
      .catch((err) => {
        console.error('Failed to fetch organization list:', err);
      });
  }, [userDetails.token, userDetails.type]);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value.trim());
  };

  const generateQRCodeValue = () => {
    const concatenatedValue = `${organization}_${selectedPlant ? selectedPlant.value : ''}_${latitude}_${longitude}`;
    console.log(concatenatedValue)
    const base64EncodedValue = btoa(concatenatedValue);
    return `https://feedbackloop.in/feedback/${base64EncodedValue}`;
  };

  const downloadQRCode = () => {
    const canvas = document.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const link = document.createElement('a');
    link.href = pngUrl;
    const qrname = `${selectedOrg.label.split(" ").join("-")}__${selectedPlant.label.split(" ").join("-")}.png`;
    link.download = qrname
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOrgChange = (selectedOption) => {
    setSelectedOrg(selectedOption);

    if (selectedOption) {
      setOrganization(selectedOption.value); // Set organization name in input field

      // Fetch plant list for the selected organization
      getPlantList(selectedOption.value, userDetails.token, userDetails.type)
        .then((res) => {
          const plants = res.data.map((plant) => ({
            value: plant.id,
            label: plant.name,
          }));
          setPlantList(plants);
          setSelectedPlant(null); // Clear selected plant
        })
        .catch((err) => {
          console.error('Failed to fetch plant list:', err);
          setPlantList([]); // Clear plant list in case of error
          setSelectedPlant(null); // Clear selected plant
        });
    } else {
      setOrganization('');
      setSelectedPlant(null); // Clear selected plant when organization is cleared
      setPlantList([]); // Clear plant list
    }
  };

  const handleLogout =() => {
    clearUserDetails();
    navigate('/login');
  }

  return (
    <div className="qr-container">
      {/* <div className="dabur-logo" style={{ textAlign: "center" }}>
          <img src={dabur_logo} alt="dabur logo" style={{ height: "50px" }} />
        </div> */}
      <div className="qr-generator">
        <h4>Dynamic QR Code Generator</h4>
        <Form.Group>
          <Select
            value={selectedOrg}
            onChange={handleOrgChange}
            options={orgList}
            placeholder="Select organization"
            className="react-select-container"
            classNamePrefix="react-select"
            isSearchable={true}
            isClearable
          />
        </Form.Group>
        <Form.Group>
          <Select
            value={selectedPlant}
            onChange={(selectedOption) => setSelectedPlant(selectedOption)}
            options={plantList}
            placeholder="Select plant"
            className="react-select-container"
            classNamePrefix="react-select"
            isSearchable={true}
            isClearable
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="number"
            value={latitude}
            onChange={handleInputChange(setLatitude)}
            placeholder="Enter latitude"
            className="qr-input"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="number"
            value={longitude}
            onChange={handleInputChange(setLongitude)}
            placeholder="Enter longitude"
            className="qr-input"
          />
        </Form.Group>
        <div className="qr-code-container" style={{ marginTop: '20px' }}>
          <QRCode value={generateQRCodeValue()} size={400} />
        </div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <button style={{background:"#004713"}} disabled={!organization || !selectedPlant || !latitude || !longitude} onClick={downloadQRCode} className="btn btn-primary mt-3">
          Download QR Code
        </button>
        <button style={{background:"#004713"}} onClick={handleLogout} className="btn btn-primary mt-3">
          Logout
        </button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeGenerator;


