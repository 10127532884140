// //localHost with dev
const config = { localPath: `https://feedbackloop.in/api`, devPath:`https://devmisafeadmin.in:3004` };

//localHost with prod
//const config = { localPath: `http://localhost:3001/api`, devPath:`https://adminmisafe.in:3004` };

//server prod
//const config = { localPath: `https://think-aloud.in/api`, devPath:`https://adminmisafe.in:3004` };

//Server dev
//const config = { localPath: `https://think-aloud.in/api`, devPath:`https://devmisafeadmin.in:3004` };

export { config };
